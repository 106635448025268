<template>
  <form autocomplete="off" @input="$emit('addressChange', address)">
    <div>
      <!-- <b-col cols="12" class="pb-3" v-if="countryLocked">
        <b-icon icon="check-circle-fill" variant="success"></b-icon>
        <b class="px-2">{{ countryLocked.Name }}</b>
      </b-col> -->
      <div class="card-body" v-if="!countryLocked">
        <label>{{ $t("dashboard.label.pleaseSelectACountry") }}</label>
        <b-form-select
          v-model="searchCountry"
          class="input-pills bg-white"
          @input="changeCountry"
        >
          <b-form-select-option :value="{}">
            {{ $t("dashboard.label.selectACountry") }}
          </b-form-select-option>
          <b-form-select-option
            v-for="country in $store.getters.countries"
            :key="country.Name"
            :value="country"
          >
            {{ country.Name }}
          </b-form-select-option>
        </b-form-select>
      </div>

      <div class="card-body">
        <div class="search-address-title">
          <label>{{ $t("dashboard.label.searchAddress") }}</label
          ><span v-if="countryLocked">
            in <span>{{ countryLocked.Name }}</span></span
          >
        </div>

        <vue-google-autocomplete
          ref="address"
          id="map"
          classname="form-control input-pills"
          placeholder="Search..."
          @placechanged="getAddressData"
          :country="countryLocked ? countryLocked.Code : searchCountry.Code"
          autocomplete="off"
          :disabled="disable"
        />
      </div>

      <div class="card-body">
        <label>{{ $t("accountSettings.label.streetAddress") }}</label>
        <b-form-input
          :disabled="disable"
          list="address-id"
          type="text"
          v-model="address.streetAddress"
          class="input-pills"
          trim
        />
      </div>
      <div class="card-body">
        <label>{{ $t("accountSettings.label.city") }}</label>
        <b-form-input
          :disabled="disable"
          type="text"
          v-model="address.city"
          class="input-pills"
        />
      </div>
      <div class="card-body">
        <label>{{ $t("dashboard.label.PostalZipCode") }}</label>
        <b-form-input
          :disabled="disable"
          type="text"
          v-model="address.postal"
          class="input-pills"
        />
      </div>
      <div class="card-body">
        <label>{{ $t("dashboard.label.ProvinceStateRegion") }}</label>
        <b-form-input :disabled="disable" v-model="address.region" class="input-pills" />
      </div>
    </div>
  </form>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: { VueGoogleAutocomplete },

  props: {
    countryLocked: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      addressData: null,
      address: {
        streetAddress: this.$store.getters.userAddress1,
        apt: this.$store.getters.userAddress2,
        city: this.$store.getters.userCity,
        region: this.$store.getters.userProvince,
        postal: this.$store.getters.userPostalCode,
        country: this.countryLocked ? this.countryLocked.Name : "",
      },
      searchCountry: {},
    };
  },
  computed: {
    disable() {
      if (!this.countryLocked) {
        return Object.keys(this.searchCountry).length == 0;
      } else return false;
    },
  },

  mounted() {
    // To demonstrate functionality of exposed component functions
    // Here we make focus on the user input
    this.$refs.address.focus();
  },

  methods: {
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function (addressData, placeResultData, id) {
      this.addressData = addressData;
      if (addressData.street_number) {
        this.address.streetAddress = addressData.street_number + " " + addressData.route;
      } else {
        this.address.streetAddress = addressData.route;
      }

      if (addressData.locality) this.address.city = addressData.locality;

      this.address.region = addressData.administrative_area_level_1;
      if (addressData.postal_code) this.address.postal = addressData.postal_code;
      this.$emit("addressChange", this.address);
      placeResultData;
      id;
    },
    changeCountry(country) {
      this.address.streetAddress = this.address.country = this.address.city = this.address.postal = this.address.region = this.address.apt =
        "";
      this.address.country = country.Name;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-address-title {
  font-size: 16px;
  color: #363636;
  span {
    font-weight: 600;
    font-size: 17px;
  }
}
</style>
